import clsn from 'classnames'
import useIsDarkMode from 'lib/hooks/useIsDarkMode'

type Props = {
  className?: any
}

export default function Logo(props: Props) {
  const isDark = useIsDarkMode()
  const secondaryColor = isDark ? '#ffffff' : '#343434'
  return (
    <div className={clsn('relative', props.className)}>
      <svg
        viewBox="0 0 1024 1024"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative"
      >
        <g clipPath="url(#clip0)">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="102"
            y="324"
            width="808"
            height="360"
          >
            <path
              d="M182.158 324.137L229.766 530.191L240.02 587.564L250.518 531.412L291.045 324.137H370.391L413.115 530.191L424.102 587.564L435.088 532.389L483.184 324.137H559.6L458.281 684H386.504L343.047 473.551L330.352 403.971L317.656 473.551L274.199 684H204.375L102.324 324.137H182.158ZM678.252 386.637V621.5H747.588C783.07 621.5 807.809 604.003 821.807 569.01C829.456 549.804 833.281 526.936 833.281 500.406C833.281 463.785 827.503 435.709 815.947 416.178C804.554 396.484 781.768 386.637 747.588 386.637H678.252ZM760.283 324.137C782.581 324.462 801.136 327.066 815.947 331.949C841.175 340.25 861.602 355.468 877.227 377.604C889.759 395.507 898.304 414.876 902.861 435.709C907.419 456.542 909.697 476.399 909.697 495.279C909.697 543.131 900.094 583.658 880.889 616.861C854.847 661.62 814.645 684 760.283 684H605.254V324.137H760.283Z"
              fill="#00AEEE"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              x="-110"
              y="165.16"
              width="1024"
              height="1518.86"
              transform="rotate(-64.23 -110 165.16)"
              fill="url(#paint0_linear)"
            />
          </g>
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="-490"
            y="197"
            width="1813"
            height="1583"
          >
            <rect
              x="1322.99"
              y="857.271"
              width="1024"
              height="1518.86"
              transform="rotate(115.767 1322.99 857.271)"
              fill="url(#paint1_linear)"
            />
          </mask>
          <g mask="url(#mask1)">
            <path
              d="M182.158 324.137L229.766 530.191L240.02 587.564L250.518 531.412L291.045 324.137H370.391L413.115 530.191L424.102 587.564L435.088 532.389L483.184 324.137H559.6L458.281 684H386.504L343.047 473.551L330.352 403.971L317.656 473.551L274.199 684H204.375L102.324 324.137H182.158ZM678.252 386.637V621.5H747.588C783.07 621.5 807.809 604.003 821.807 569.01C829.456 549.804 833.281 526.936 833.281 500.406C833.281 463.785 827.503 435.709 815.947 416.178C804.554 396.484 781.768 386.637 747.588 386.637H678.252ZM760.283 324.137C782.581 324.462 801.136 327.066 815.947 331.949C841.175 340.25 861.602 355.468 877.227 377.604C889.759 395.507 898.304 414.876 902.861 435.709C907.419 456.542 909.697 476.399 909.697 495.279C909.697 543.131 900.094 583.658 880.889 616.861C854.847 661.62 814.645 684 760.283 684H605.254V324.137H760.283Z"
              fill="#00AEEE"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-55.8766"
            y1="900.235"
            x2="279.75"
            y2="887.002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="1328.66"
            y1="1828.9"
            x2="1758.44"
            y2="1927.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="1024" height="1024" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

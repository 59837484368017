import React from 'react'
import clsn from 'classnames'

export const LIGHT_BG_CLASSES = 'bg-white'
export const DARK_BG_CLASSES = 'bg-black'
export const SCREEN_BG_CLASSES = 'bg-white dark:bg-gray-900'

type ScreenProps = {
  children: any
}

export default function Screen({ children }: ScreenProps) {
  return (
    <div className={clsn('min-h-screen min-w-full', SCREEN_BG_CLASSES)}>
      {children}
    </div>
  )
}

import Header from 'domains/applications/components/Header'
import Hero from 'domains/applications/components/Hero'
import Screen from 'domains/applications/components/Screen'
import Services from 'domains/applications/components/Services'

export default function App() {
  return (
    <Screen>
      <div className="relative overflow-hidden">
        <Header />
        <main>
          <Hero />
          <div className="my-52">
            <Services />
          </div>
        </main>
      </div>
    </Screen>
  )
}

import React from 'react'

export default function useIsDarkMode() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  )

  React.useEffect(() => {
    const modeMe = (e: any) => {
      setMode(e.matches ? 'dark' : 'light')
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', modeMe)
    return window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', modeMe)
  }, [])

  return mode === 'dark'
}

import clsn from 'classnames'

import CloudLogo from 'lib/components/CloudLogo'

export default function Hero() {
  return (
    <div
      className={clsn(
        'bg-gray-900',
        'pt-10 sm:pt-16 lg:pt-8 pb-14 lg:overflow-hidden',
      )}
    >
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white dark:text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">A better way to</span>
                <span className="block text-primary">ship your apps</span>
              </h1>
              <p className="mt-3 text-base text-gray-600 dark:text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Willful Divide is a best in class software consulting agency.
                Companies choose Willful Divide to deliver on their most
                important projects.
              </p>
            </div>
          </div>
          <div className="mt-12 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <CloudLogo noDarkMode />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import csln from 'classnames'
import {
  AdjustmentsIcon,
  ServerIcon,
  DeviceMobileIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline'

import { SCREEN_BG_CLASSES } from 'domains/applications/components/Screen'
import DotPattern from 'lib/components/DotPattern'
import { TEXT_COLORS_CLASSES } from 'lib/components/Text'

const features = [
  {
    name: 'Mobile Apps',
    icon: DeviceMobileIcon,
    description:
      'Using React Native, we are able to ship your mobile app to both iOS and Android simultaneously.',
  },
  {
    name: 'Websites',
    icon: GlobeAltIcon,
    description:
      'We can help craft anything from marketing sites, to online stores, to blogs.',
  },
  {
    name: 'Backend Development',
    icon: ServerIcon,
    description:
      'We create fast and efficient code to run whatever your business needs. We specialize in Node.js, MySQL, Firebase and much more.',
  },
  {
    name: 'Custom Software',
    icon: AdjustmentsIcon,
    description:
      'We can create dedicated software tailored to your unique business goals. We will work with you through every step of the software process to ensure your goals are reached.',
  },
]

export default function Services() {
  return (
    <div className={csln(SCREEN_BG_CLASSES, 'overflow-hidden')} id="services">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <DotPattern />

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2
              className={csln(
                TEXT_COLORS_CLASSES,
                'text-3xl font-extrabold tracking-tight sm:text-4xl',
              )}
            >
              Services we prvide
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p
                    className={csln(
                      TEXT_COLORS_CLASSES,
                      'mt-5 text-lg leading-6 font-medium',
                    )}
                  >
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

import clsn from 'classnames'

export const LIGHT_TEXT_COLOR_CLASS = 'text-gray-700'
export const DARK_TEXT_COLOR_CLASS = 'text-gray-100'
export const TEXT_COLORS_CLASSES = 'text-gray-700 dark:text-gray-100'

type TextProps = {
  children: any
  className?: any
  overrideColor?: boolean
}

export default function Text(props: TextProps) {
  return (
    <p
      className={clsn(
        {
          [TEXT_COLORS_CLASSES]: !props.overrideColor,
        },
        props.className,
      )}
    >
      {props.children}
    </p>
  )
}

import React from 'react'
import clsn from 'classnames'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import Logo from 'lib/components/Logo'
import { SCREEN_BG_CLASSES } from 'domains/applications/components/Screen'
import { TEXT_COLORS_CLASSES } from 'lib/components/Text'

const navigation = [
  { name: 'Services', href: '#services' },
  { name: 'Clients', href: '#clients' },
  { name: 'Contact', href: '#contact' },
]

type HeaderProps = {}

export default function Header(props: HeaderProps) {
  return (
    <Popover as="header" className="relative">
      {({ open }) => (
        <>
          <div className={clsn(SCREEN_BG_CLASSES, 'pt-6')}>
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="/">
                    <span className="sr-only">Willful Divide</span>
                    <Logo className="w-10 h-10" />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button
                      className={clsn(
                        SCREEN_BG_CLASSES,
                        'rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white',
                      )}
                    >
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-8 md:flex md:ml-10">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={clsn(
                        TEXT_COLORS_CLASSES,
                        'text-base font-medium hover:text-primary',
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>

          <Transition
            show={open}
            as={React.Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
            >
              <div
                className={clsn(
                  SCREEN_BG_CLASSES,
                  'rounded-lg shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden',
                )}
              >
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <Logo className="h-8 w-8" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button
                      className={clsn(
                        'bg-gray-200 dark:bg-gray-800 rounded-md p-2',
                        'inline-flex items-center justify-center',
                        'text-gray-400 hover:bg-gray-900 hover:dark:bg-gray-900 focus:outline-none',
                        'focus:ring-2 focus:ring-inset focus:ring-primary',
                      )}
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={clsn(
                          TEXT_COLORS_CLASSES,
                          'block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-50',
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

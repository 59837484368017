import clsn from 'classnames'

import useIsDarkMode from 'lib/hooks/useIsDarkMode'

type Props = {
  noDarkMode?: boolean
  className?: any
}

export default function CloudLogo(props: Props) {
  const isDark = useIsDarkMode()
  const secondaryColor = props.noDarkMode || isDark ? '#fff' : '#343434'
  return (
    <div className={clsn('relative', props.className)}>
      <svg viewBox="0 0 601 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.07"
          d="M546.232 252.902C546.232 256.16 546.16 259.418 545.943 262.459C577.053 286.135 601 336.889 601 381.561C601 430.216 572.567 453.167 537.478 432.895C537.406 432.822 537.333 432.822 537.261 432.75L316.815 305.539C316.742 305.466 316.67 305.466 316.525 305.394C277.095 282.587 245.117 219.814 245.117 165.15C245.117 113.961 273.188 87.9689 309.146 103.68C313.559 68.565 337.072 53.4329 365.433 69.8683C379.035 77.7602 391.551 91.589 401.318 108.242C415.498 87.1725 439.663 81.8147 467.01 97.5984C510.781 122.795 546.232 192.373 546.232 252.902Z"
          fill="url(#paint0_linear)"
        />
        <path
          opacity="0.4"
          d="M510.058 287.655C510.058 290.913 509.986 294.172 509.769 297.212C540.879 320.888 564.826 371.642 564.826 416.315C564.826 464.969 536.393 487.921 501.304 467.648C501.232 467.576 501.159 467.576 501.087 467.503L280.641 340.292C280.569 340.22 280.496 340.22 280.351 340.147C240.921 317.34 208.943 254.567 208.943 199.903C208.943 148.715 237.015 122.722 272.972 138.434C277.385 103.318 300.898 88.1863 329.259 104.622C342.861 112.514 355.377 126.342 365.144 142.995C379.324 121.926 403.489 116.568 430.837 132.352C474.608 157.548 510.058 227.127 510.058 287.655Z"
          fill="url(#paint1_linear)"
        />
        <path
          opacity="0.2"
          d="M161.048 349.632L127.479 330.228V318.861L161.048 338.265C163.798 339.858 167.777 343.043 171.177 347.967C174.867 353.252 177.471 359.913 177.471 367.153C177.471 375.118 175.446 379.679 171.539 380.982C167.921 382.213 163.653 380.113 160.976 378.593L147.157 370.629L147.085 370.556C145.927 369.832 144.046 369.181 142.527 369.615C141.297 369.977 139.922 371.208 139.922 375.624C139.922 380.041 141.297 382.792 142.455 384.385C143.902 386.412 145.783 387.86 146.94 388.44L147.085 388.512L189.264 412.912V424.279L147.302 400.024C144.408 398.504 140.284 395.318 136.667 390.25C132.76 384.747 130.011 377.724 130.083 369.905C130.083 362.158 132.76 358.175 136.667 357.089C140.284 356.076 144.408 357.524 147.302 359.334L160.976 367.226C162.929 368.384 164.449 368.891 165.389 368.529C166.113 368.312 167.56 367.226 167.56 361.434C167.56 357.741 166.33 355.279 165.245 353.614C163.942 351.732 162.134 350.284 161.048 349.632Z"
          fill="white"
        />
        <path
          className="animate-pulse"
          d="M134.714 327.404C134.714 336.744 129.215 341.161 122.487 337.323C115.758 333.414 110.26 322.698 110.26 313.286C110.26 303.946 115.758 299.529 122.487 303.367C129.215 307.276 134.714 318.064 134.714 327.404Z"
          fill="url(#paint2_linear)"
        />
        <path
          className="animate-pulse"
          d="M206.339 428.044C206.339 437.384 200.84 441.8 194.112 437.963C187.383 434.053 181.885 423.338 181.885 413.925C181.885 404.585 187.383 400.169 194.112 404.006C200.84 407.916 206.339 418.704 206.339 428.044Z"
          fill="url(#paint3_linear)"
        />
        <path
          opacity="0.07"
          d="M264.145 187.522L481.118 312.851V427.754L264.072 302.425L264.145 187.522Z"
          fill="url(#paint4_linear)"
        />
        <path
          opacity="0.3"
          d="M235.133 202.003L452.106 327.332V442.235L235.133 316.906V202.003Z"
          fill="url(#paint5_linear)"
        />
        <path
          className="animate-pulse"
          d="M254.594 270.424C254.594 279.691 260.092 290.335 266.821 294.244C273.549 298.154 279.048 293.81 279.048 284.542C279.048 275.275 273.549 264.632 266.821 260.722C260.092 256.812 254.594 261.156 254.594 270.424Z"
          fill="url(#paint6_linear)"
        />
        <path
          opacity="0.15"
          d="M285.199 288.307C285.199 292.579 287.731 297.575 290.915 299.385C294.026 301.195 296.558 299.168 296.558 294.896C296.558 290.624 294.026 285.628 290.842 283.818C287.731 282.008 285.199 284.035 285.199 288.307Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M302.635 298.371C302.635 302.642 305.167 307.638 308.35 309.448C311.461 311.258 313.994 309.231 313.994 304.959C313.994 300.688 311.461 295.692 308.278 293.882C305.167 292.072 302.635 294.099 302.635 298.371Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M397.338 317.919V383.299L388.801 378.376V312.996L397.338 317.919Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M416.004 328.78V394.159L407.467 389.236V323.856L416.004 328.78Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M434.742 339.64V405.02L426.205 400.096V334.717L434.742 339.64Z"
          fill="white"
        />
        <path
          d="M264.072 344.563L481.046 469.892V584.795L264.072 459.466V344.563Z"
          fill="url(#paint7_linear)"
        />
        <path
          opacity="0.3"
          d="M235.133 359.768L452.106 485.097V600L235.133 474.671V359.768Z"
          fill="url(#paint8_linear)"
        />
        <path
          className="animate-pulse"
          d="M254.594 428.188C254.594 437.456 260.092 448.099 266.821 452.009C273.549 455.919 279.048 451.574 279.048 442.307C279.048 433.039 273.549 422.396 266.821 418.487C260.092 414.577 254.594 418.921 254.594 428.188Z"
          fill="url(#paint9_linear)"
        />
        <path
          opacity="0.15"
          d="M285.199 446.145C285.199 450.416 287.731 455.412 290.915 457.222C294.026 459.032 296.558 457.005 296.558 452.733C296.558 448.461 294.026 443.466 290.842 441.656C287.731 439.846 285.199 441.8 285.199 446.145Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M302.562 456.208C302.562 460.48 305.095 465.476 308.278 467.286C311.389 469.096 313.921 467.069 313.921 462.797C313.921 458.525 311.389 453.53 308.206 451.719C305.095 449.837 302.562 451.864 302.562 456.208Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M397.338 477.929V543.309L388.801 538.385V473.006L397.338 477.929Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M416.004 488.79V554.169L407.467 549.246V483.866L416.004 488.79Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M434.742 499.65V565.029L426.205 560.106V494.727L434.742 499.65Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M42.4688 31.7847L177.688 109.907V360.637L42.4688 282.442V31.7847Z"
          fill="url(#paint10_linear)"
        />
        {/* Person head */}
        <path
          className="animate-pulse"
          d="M125.959 122.577C125.959 135.103 118.724 141.04 109.825 135.827C100.926 130.614 93.6914 116.278 93.6914 103.753C93.6914 91.2272 100.926 85.2901 109.825 90.5031C118.724 95.7161 125.959 110.052 125.959 122.577Z"
          fill="url(#paint11_linear)"
        />
        {/* Person body */}
        <path
          className="animate-pulse"
          d="M109.825 145.891C95.4274 137.493 83.5622 145.384 81.4641 163.485C81.1024 166.598 83.4899 170.798 86.3838 172.463L133.338 199.904C136.232 201.569 138.62 200.266 138.258 196.645C136.087 176.011 124.15 154.29 109.825 145.891Z"
          fill="url(#paint12_linear)"
        />
        <path
          opacity="0.15"
          d="M69.5996 194.401L150.196 240.956V250.802L69.5996 204.248V194.401Z"
          fill="white"
        />
        <path
          opacity="0.15"
          d="M77.7754 218.656L141.659 255.509V265.355L77.7754 228.502V218.656Z"
          fill="white"
        />
        <path
          className="animate-pulse"
          d="M94.0532 260.07L0 205.696V233.716L94.0532 288.09V260.07Z"
          fill="url(#paint13_linear)"
        />
        {/* Up arrow */}
        <path
          opacity="0.2"
          d="M206.845 0L226.958 39.5318C229.707 44.962 229.273 50.3922 225.945 51.6954C222.617 52.9987 217.698 49.5957 214.948 44.1656L214.731 43.6587V87.5347C214.731 92.5305 211.259 94.5577 206.918 92.096C202.577 89.5619 199.104 83.5525 199.104 78.5568V34.6808L198.887 34.898C196.138 37.1425 191.146 34.8256 187.89 29.685C184.562 24.5445 184.128 18.6075 186.877 16.363L206.845 0Z"
          fill="url(#paint14_linear)"
        />
        {/* Down arrow */}
        <path
          opacity="0.2"
          d="M206.845 583.709L186.733 544.177C183.983 538.747 184.417 533.317 187.745 532.014C191.073 530.711 195.993 534.113 198.742 539.544L198.959 540.05V496.174C198.959 491.179 202.432 489.151 206.773 491.613C211.114 494.147 214.587 500.157 214.587 505.152V549.028L214.804 548.811C217.553 546.567 222.545 548.884 225.801 554.024C229.129 559.165 229.563 565.102 226.814 567.346L206.845 583.709Z"
          fill="url(#paint15_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="588.058"
            y1="114.638"
            x2="364.033"
            y2="326.972"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="551.876"
            y1="149.392"
            x2="327.852"
            y2="361.726"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="134.714"
            y1="320.566"
            x2="110.26"
            y2="320.566"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="206.339"
            y1="421.206"
            x2="181.885"
            y2="421.206"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="368.16"
            y1="196.711"
            x2="376.256"
            y2="399.958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="339.219"
            y1="211.191"
            x2="347.315"
            y2="414.438"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="279.048"
            y1="277.702"
            x2="254.594"
            y2="277.702"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="368.144"
            y1="353.776"
            x2="376.24"
            y2="557.023"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="339.204"
            y1="368.981"
            x2="347.3"
            y2="572.227"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="279.048"
            y1="435.467"
            x2="254.594"
            y2="435.467"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="192.18"
            y1="53.8207"
            x2="72.012"
            y2="261.857"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint11_linear"
            x1="125.959"
            y1="113.461"
            x2="93.6914"
            y2="113.461"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint12_linear"
            x1="138.294"
            y1="171.925"
            x2="81.4277"
            y2="171.925"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint13_linear"
            x1="94.0532"
            y1="247.389"
            x2="2.42417e-06"
            y2="247.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00aeee" />
            <stop offset="1" stopColor="#00aeee" />
          </linearGradient>
          <linearGradient
            id="paint14_linear"
            x1="221.158"
            y1="39.8764"
            x2="184.449"
            y2="60.3243"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint15_linear"
            x1="192.494"
            y1="543.86"
            x2="229.203"
            y2="523.412"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={secondaryColor} />
            <stop offset="1" stopColor={secondaryColor} stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
